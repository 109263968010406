<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="revenue-title" :style="titleStyle">{{ name }}</span>
      <v-tooltip
        v-if="tooltipText"
        max-width="300"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            color="primary"
            class="ml-2"
            v-bind="attrs"
            style="cursor: pointer;"
            v-on="on"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
        </template>
        <span>{{ tooltipText }}</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text v-show="isLoading">
      <statistics-card-shimmer />
    </v-card-text>

    <v-card-text v-show="!isLoading">
      <div class="d-flex align-center">
        <h2 class="revenue-amount" :style="valueStyle">
          {{ formattedValue }}
        </h2>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import StatisticsCardShimmer from './StatisticsCardShimmer.vue'

export default {
  name: 'StatisticsCard',
  components: {
    StatisticsCardShimmer,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    tooltipText: {
      type: String,
      default: null,
    },
    titleFontSize: {
      type: String,
      default: '18px'
    },
    titleFontWeight: {
      type: [String, Number],
      default: 'normal'
    },
    valueFontSize: {
      type: String,
      default: '28px'
    },
    valueFontWeight: {
      type: [String, Number],
      default: 600
    }
  },
  data() {
    return {
      icons: { mdiInformationOutline },
    }
  },
  computed: {
    formattedValue() {
      if (typeof this.value === 'number') {
        return this.value.toLocaleString()
      }
      return this.value
    },
    titleStyle() {
      return {
        fontSize: this.titleFontSize,
        fontWeight: this.titleFontWeight
      }
    },
    valueStyle() {
      return {
        fontSize: this.valueFontSize,
        fontWeight: this.valueFontWeight
      }
    }
  },
}
</script>

<style>
.revenue-title {
  font-size: 18px;
}

.revenue-amount {
  font-size: 28px;
  font-weight: 600;
}

.revenue-icon {
  margin-right: 8px;
}

.success--text {
  color: var(--success);
}

.error--text {
  color: var(--error);
}
</style>

<template>
  <v-row>
    <v-col
      v-for="(count, type) in formattedCounts"
      :key="type"
      cols="12"
      md="3"
    >
      <statistics-card
        :name="count.label"
        :value="count.value"
        :is-loading="isLoading"
        class="customers-count-card"
        title-font-size="15px"
        title-font-weight="500"
        value-font-size="22px"
        value-font-weight="550"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import StatisticsCard from '@/views/admin/analytics/customers/components/StatisticsCard.vue'

export default {
  name: 'CustomersCountStatisticsCard',
  components: {
    StatisticsCard,
  },

  props: {
    stationId: {
      type: [String, Number],
      default: null,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      customerCounts: {
        pppoe: 0,
        static_ip: 0,
        dynamic_ip: 0,
        hotspot: 0,
        total: 0,
      },
      isLoading: true,
      errorMessage: null,
      countLabels: {
        pppoe: 'PPPOE Customers',
        static_ip: 'Static IP Customers',
        dynamic_ip: 'Dynamic IP Customers',
        hotspot: 'Hotspot Customers'
      }
    }
  },

  computed: {
    formattedCounts() {
      const result = {}

      Object.keys(this.countLabels).forEach(key => {
        result[key] = {
          label: this.countLabels[key],
          value: this.customerCounts[key].toString()
        }
      })

      return result
    }
  },

  watch: {
    // Re-fetch data when filters change
    filters: {
      handler() {
        this.fetchCustomerCounts()
      },
      deep: true,
    },

    stationId() {
      this.fetchCustomerCounts()
    },
  },

  mounted() {
    this.fetchCustomerCounts()
  },

  methods: {
    async fetchCustomerCounts() {
      this.isLoading = true
      this.errorMessage = null

      try {
        // Prepare query parameters
        const params = { ...this.filters }
        if (this.stationId) {
          params.stationId = this.stationId
        }

        // Fetch data from API
        const response = await axios.get('analytics/customers/connection-type-count', { params })

        // Update the data
        this.customerCounts = response.data
      } catch (error) {
        console.error('Error fetching customer counts:', error)
        this.errorMessage = 'Failed to load customer data'
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style>
/* Global styles to override StatisticsCard styling when used within CustomersCountStatisticsCard */
.customers-count-card .revenue-title {
  font-size: 15px !important;
  font-weight: 500 !important; /* 'medium' is not a valid value, use numeric values */
}

.customers-count-card .revenue-amount {
  font-size: 22px !important;
  font-weight: 550 !important;
}
</style>
